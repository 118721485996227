import { useEffect, useState } from 'react';

const KONAMI_CODE = [
  'ArrowUp', 'ArrowUp',
  'ArrowDown', 'ArrowDown',
  'ArrowLeft', 'ArrowRight',
  'ArrowLeft', 'ArrowRight',
  'b', 'a'
];

const EasterEgg = () => {
  const [codeProgress, setCodeProgress] = useState(0);

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === KONAMI_CODE[codeProgress]) {
        const newProgress = codeProgress + 1;
        setCodeProgress(newProgress);

        if (newProgress === KONAMI_CODE.length) {
          activateHackerMode();
          setCodeProgress(0);
        }
      } else {
        setCodeProgress(0);
      }
    };

    const activateHackerMode = () => {
      // Create matrix rain effect
      const canvas = document.createElement('canvas');
      canvas.id = 'matrixRain';
      canvas.style.position = 'fixed';
      canvas.style.top = '0';
      canvas.style.left = '0';
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.style.zIndex = '1000';
      canvas.style.pointerEvents = 'none';
      canvas.style.opacity = '0.1';
      document.body.appendChild(canvas);

      const context = canvas.getContext('2d')!;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789$@#%&*';
      const fontSize = 14;
      const columns = canvas.width / fontSize;
      const drops: number[] = [];

      for (let i = 0; i < columns; i++) {
        drops[i] = 1;
      }

      const draw = () => {
        context.fillStyle = 'rgba(0, 0, 0, 0.05)';
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = '#0F0';
        context.font = `${fontSize}px monospace`;

        for (let i = 0; i < drops.length; i++) {
          const text = characters[Math.floor(Math.random() * characters.length)];
          context.fillText(text, i * fontSize, drops[i] * fontSize);
          if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
            drops[i] = 0;
          }
          drops[i]++;
        }
      };

      // Show success message
      const message = document.createElement('div');
      message.style.position = 'fixed';
      message.style.top = '50%';
      message.style.left = '50%';
      message.style.transform = 'translate(-50%, -50%)';
      message.style.zIndex = '1001';
      message.style.background = 'rgba(0, 0, 0, 0.95)';
      message.style.padding = '2rem';
      message.style.borderRadius = '8px';
      message.style.border = '1px solid #00ff00';
      message.style.color = '#00ff00';
      message.style.fontFamily = 'monospace';
      message.style.textAlign = 'center';
      message.style.boxShadow = '0 0 20px rgba(0, 255, 0, 0.2)';
      message.innerHTML = `
        <div style="font-size: 24px; margin-bottom: 10px;">
          <span style="font-family: monospace;">[ HACKER MODE ACTIVATED ]</span>
        </div>
        <div style="font-size: 14px; margin: 20px 0;">
          Interested in working with our team?
        </div>
        <div id="copyEmailBtn"
             style="width: 100%; font-size: 16px; margin: 15px 0; cursor: pointer; padding: 10px; 
                    background: rgba(0, 255, 0, 0.1); border: none; color: #00ff00;
                    font-family: monospace; border-radius: 4px; transition: all 0.3s ease;
                    user-select: none;">
          team@marketviz.app
          <div style="font-size: 12px; opacity: 0.7;">[Click to Copy]</div>
        </div>
        <div id="copyConfirm" 
             style="font-size: 12px; margin-top: 5px; opacity: 0; 
                    transition: opacity 0.3s ease; color: #00ff00;">
          Copied to clipboard!
        </div>
        <div style="font-size: 12px; margin-top: 20px; opacity: 0.7;">
          > Press ESC to return to normal mode_
        </div>
      `;
      document.body.appendChild(message);

      // Simplified copy functionality
      const copyEmailBtn = message.querySelector('#copyEmailBtn') as HTMLDivElement;
      const copyConfirm = message.querySelector('#copyConfirm');
      
      if (copyEmailBtn && copyConfirm) {
        copyEmailBtn.addEventListener('click', () => {
          const email = 'team@marketviz.app';
          
          // Create temporary input element
          const tempInput = document.createElement('input');
          tempInput.value = email;
          document.body.appendChild(tempInput);
          
          // Select and copy
          tempInput.select();
          document.execCommand('copy');
          
          // Remove temporary element
          document.body.removeChild(tempInput);
          
          // Visual feedback
          copyEmailBtn.style.background = 'rgba(0, 255, 0, 0.2)';
          (copyConfirm as HTMLElement).style.opacity = '1';
          
          // Reset after 2 seconds
          setTimeout(() => {
            copyEmailBtn.style.background = 'rgba(0, 255, 0, 0.1)';
            (copyConfirm as HTMLElement).style.opacity = '0';
          }, 2000);
        });

        // Hover effects
        copyEmailBtn.addEventListener('mouseenter', () => {
          copyEmailBtn.style.background = 'rgba(0, 255, 0, 0.15)';
        });
        
        copyEmailBtn.addEventListener('mouseleave', () => {
          copyEmailBtn.style.background = 'rgba(0, 255, 0, 0.1)';
        });
      }

      // Add a subtle pulse animation to the message
      let opacity = 1;
      const pulseAnimation = setInterval(() => {
        opacity = opacity === 1 ? 0.8 : 1;
        message.style.opacity = opacity.toString();
      }, 1000);

      // Start animation
      const animation = setInterval(draw, 33);

      // Update cleanup function to include pulse animation and remove event listeners
      const cleanup = () => {
        clearInterval(animation);
        clearInterval(pulseAnimation);
        if (copyEmailBtn) {
          copyEmailBtn.removeEventListener('click', () => {});
          copyEmailBtn.removeEventListener('mouseenter', () => {});
          copyEmailBtn.removeEventListener('mouseleave', () => {});
        }
        canvas.remove();
        message.remove();
      };

      // Handle ESC key to deactivate
      const handleEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          cleanup();
          document.removeEventListener('keyup', handleEsc);
        }
      };

      document.addEventListener('keyup', handleEsc);

      // Handle window resize
      const handleResize = () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      };
      window.addEventListener('resize', handleResize);

      // Cleanup on component unmount
      return () => {
        cleanup();
        window.removeEventListener('resize', handleResize);
      };
    };

    document.addEventListener('keyup', handleKeyUp);
    return () => document.removeEventListener('keyup', handleKeyUp);
  }, [codeProgress]);

  return null;
};

export default EasterEgg;