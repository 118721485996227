import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Activity, Box, Clock, Fuel, TrendingUp, AlertTriangle } from 'lucide-react';

const BlockchainStatus = () => {
  const [blockNumber, setBlockNumber] = useState<number | null>(null);
  const [ethPrice, setEthPrice] = useState<number | null>(null);
  const [gasPrice, setGasPrice] = useState<number | null>(null);
  const [blockTime, setBlockTime] = useState<string | null>(null);
  const [priceChange, setPriceChange] = useState<number | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [networkLoad, setNetworkLoad] = useState<'Low' | 'Medium' | 'High' | null>(null);

  useEffect(() => {
    const provider = new ethers.JsonRpcProvider('https://eth.llamarpc.com');
    let lastBlockTimestamp: number | null = null;
    
    const updateBlockchainData = async () => {
      try {
        // Get block data
        const block = await provider.getBlock('latest');
        if (block) {
          setBlockNumber(block.number);
          setIsConnected(true);
          
          // Calculate block time
          if (lastBlockTimestamp) {
            const timeDiff = block.timestamp - lastBlockTimestamp;
            setBlockTime(`${timeDiff}s`);
          }
          lastBlockTimestamp = block.timestamp;
        }

        // Get gas price
        const feeData = await provider.getFeeData();
        if (feeData.gasPrice) {
          const gasPriceGwei = Number(ethers.formatUnits(feeData.gasPrice, 'gwei'));
          setGasPrice(Math.round(gasPriceGwei));
          
          // Set network load indicator
          setNetworkLoad(
            gasPriceGwei < 30 ? 'Low' : 
            gasPriceGwei < 100 ? 'Medium' : 'High'
          );
        }
      } catch (error) {
        console.error('Blockchain data fetch error:', error);
        setIsConnected(false);
      }
    };

    const updateEthPrice = async () => {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd&include_24hr_change=true'
        );
        const data = await response.json();
        setEthPrice(data.ethereum.usd);
        setPriceChange(data.ethereum.usd_24h_change);
      } catch (error) {
        console.error('Price fetch error:', error);
      }
    };

    // Initial updates
    updateBlockchainData();
    updateEthPrice();

    // Set up intervals
    const blockInterval = setInterval(updateBlockchainData, 12000);
    const priceInterval = setInterval(updateEthPrice, 30000);

    return () => {
      clearInterval(blockInterval);
      clearInterval(priceInterval);
    };
  }, []);

  const getNetworkLoadColor = (load: string | null) => {
    switch (load) {
      case 'Low': return 'text-green-400';
      case 'Medium': return 'text-yellow-400';
      case 'High': return 'text-red-400';
      default: return 'text-gray-400';
    }
  };

  return (
    <div className="sm:fixed sm:top-16 sm:right-0 sm:m-4 z-40 flex flex-col gap-2 
                    sm:flex-row sm:items-center max-w-[calc(100vw-2rem)]
                    fixed left-0 right-0 top-[4.5rem] px-4 sm:px-0">
      {/* First Row - Connection Status, Block Time, Network Load */}
      <div className="flex flex-row gap-2 flex-wrap justify-end max-w-full">
        {/* Connection Status */}
        <div className="flex items-center gap-2 bg-black/60 backdrop-blur-sm px-3 py-1.5 rounded-lg border border-green-500/30 shrink-0">
          <Activity className={`w-4 h-4 ${isConnected ? 'text-green-400 animate-pulse' : 'text-red-400'}`} />
          <span className="text-xs font-mono truncate">
            {blockNumber ? `#${blockNumber.toString().replace(/,/g, '')}` : 'Connecting...'}
          </span>
        </div>
        
        {/* Block Time */}
        {blockTime && (
          <div className="flex items-center gap-2 bg-black/60 backdrop-blur-sm px-3 py-1.5 rounded-lg border border-green-500/30 shrink-0">
            <Clock className="w-4 h-4 text-green-400" />
            <span className="text-xs font-mono truncate">{blockTime}</span>
          </div>
        )}

        {/* Network Load */}
        {networkLoad && (
          <div className="flex items-center gap-2 bg-black/60 backdrop-blur-sm px-3 py-1.5 rounded-lg border border-green-500/30 shrink-0">
            <AlertTriangle className={`w-4 h-4 ${getNetworkLoadColor(networkLoad)}`} />
            <span className="text-xs font-mono truncate">
              {networkLoad} Load
            </span>
          </div>
        )}
      </div>

      {/* Second Row - Gas Price and ETH Price */}
      <div className="flex flex-row gap-2 flex-wrap justify-end max-w-full">
        {/* Gas Price */}
        {gasPrice && (
          <div className="flex items-center gap-2 bg-black/60 backdrop-blur-sm px-3 py-1.5 rounded-lg border border-green-500/30 shrink-0">
            <Fuel className="w-4 h-4 text-green-400" />
            <span className="text-xs font-mono truncate">
              {gasPrice} Gwei
            </span>
          </div>
        )}
        
        {/* ETH Price */}
        {ethPrice && (
          <div className="flex items-center gap-2 bg-black/60 backdrop-blur-sm px-3 py-1.5 rounded-lg border border-green-500/30 group shrink-0">
            <Box className="w-4 h-4 text-green-400" />
            <span className="text-xs font-mono truncate">
              ETH ${ethPrice.toString().replace(/,/g, '')}
            </span>
            {priceChange && (
              <span className={`text-xs font-mono hidden group-hover:inline truncate
                ${priceChange >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                <TrendingUp className={`w-3 h-3 inline ${priceChange >= 0 ? '' : 'rotate-180'}`} />
                {priceChange.toFixed(2)}%
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlockchainStatus;