import { motion } from 'framer-motion';
import { Search, Scan, TrendingUp } from 'lucide-react';

export const FeatureWidgets: React.FC = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {/* Find Widget */}
      <motion.div 
        whileHover={{ scale: 1.05 }}
        className="relative h-64 bg-black/60 rounded-lg border border-green-500/30 overflow-hidden group"
      >
        <div className="absolute inset-0">
          <div className="absolute inset-0 rounded-full border border-green-500/30 
                        animate-radar origin-center">
            <div className="absolute top-1/2 left-1/2 w-1/2 h-px bg-gradient-to-r from-green-500/60 to-transparent" />
          </div>
          {[...Array(3)].map((_, i) => (
            <div key={i} className="absolute inset-0 rounded-full border border-green-500/20"
                 style={{ transform: `scale(${0.6 + i * 0.2})` }} />
          ))}
        </div>
        <div className="relative h-full flex flex-col items-center justify-center p-6">
          <Search className="w-12 h-12 mb-4 text-green-400 group-hover:animate-pulse" />
          <h3 className="text-xl font-bold mb-2">Find</h3>
          <p className="text-green-400/70 text-center">Advanced token discovery engine</p>
        </div>
      </motion.div>

      {/* Scan Widget */}
      <motion.div 
        whileHover={{ scale: 1.05 }}
        className="relative h-64 bg-black/60 rounded-lg border border-green-500/30 overflow-hidden group"
      >
        <div className="absolute inset-0">
          {/* Grid background */}
          <div className="absolute inset-0 grid grid-cols-4 gap-4">
            {[...Array(16)].map((_, i) => (
              <div key={i} className="bg-green-500/5 border border-green-500/10" />
            ))}
          </div>
          
          {/* Scanning line */}
          <div className="absolute inset-0">
            <div className="absolute top-0 left-0 w-full h-full animate-scan">
              <div className="w-full h-px bg-gradient-to-r from-transparent via-green-500/60 to-transparent 
                            shadow-[0_0_10px_rgba(34,197,94,0.5)]" />
            </div>
          </div>
        </div>
        
        <div className="relative h-full flex flex-col items-center justify-center p-6">
          <Scan className="w-12 h-12 mb-4 text-green-400 group-hover:animate-pulse" />
          <h3 className="text-xl font-bold mb-2">Scan</h3>
          <p className="text-green-400/70 text-center">Comprehensive token analytics suite</p>
        </div>
      </motion.div>

      {/* Trade Widget */}
      <motion.div 
        whileHover={{ scale: 1.05 }}
        className="relative h-64 bg-black/60 rounded-lg border border-green-500/30 overflow-hidden group"
      >
        <div className="absolute inset-0">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="absolute inset-0">
              <div className="absolute h-px w-full bg-gradient-to-r from-transparent via-green-500/40 to-transparent 
                            animate-dataFlow" 
                   style={{ 
                     top: `${30 + i * 30}%`,
                     animationDelay: `${i * 1}s`
                   }} />
            </div>
          ))}
        </div>
        <div className="relative h-full flex flex-col items-center justify-center p-6">
          <TrendingUp className="w-12 h-12 mb-4 text-green-400 group-hover:animate-pulse" />
          <h3 className="text-xl font-bold mb-2">Trade</h3>
          <p className="text-green-400/70 text-center">Enterprise-grade trading automation</p>
        </div>
      </motion.div>
    </div>
  );
}; 