import { useState, useEffect } from 'react';
import { Bot, Send, ChevronDown, ExternalLink, Twitter, TrendingUp, Shield, Settings, Users, Zap, AlertTriangle, Terminal, Menu, X } from 'lucide-react';
import { FeatureWidgets } from './FeatureWidgets';
import BlockchainStatus from './BlockchainStatus';
import EasterEgg from './EasterEgg';

const LandingPage = () => {
  const [currentHeadline, setCurrentHeadline] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const [titleOpacity, setTitleOpacity] = useState(1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const headlines = [
    {
      title: "AI-Powered Trading at Your Fingertips",
      subtitle: "Let advanced algorithms and real-time analytics automate your crypto success"
    },
    {
      title: "The Future of Trading is Here",
      subtitle: "Harness AI technology to execute perfect trades 24/7 while you sleep"
    },
    {
      title: "Your Personal Trading Command Center",
      subtitle: "Professional-grade tools, market analysis, and automated strategies in one platform"
    },
    {
      title: "Trade Smarter, Not Harder",
      subtitle: "AI-assisted analysis spots opportunities and automates trades with surgical precision"
    },
    {
      title: "Next-Gen Trading Intelligence",
      subtitle: "Advanced algorithms analyze millions of data points to find the most profitable trades"
    }
  ];

  const membershipTiers = [
    {
      name: "Rookie",
      tokens: "50K $VIZ",
      features: [
        "Token Inception Signals",
        "AI Analysis & Prediction Tools",
        "TX Live Watch",
        "0% Trade Fee (Beta)",
      ],
      highlight: false
    },
    {
      name: "Trader",
      tokens: "250K $VIZ",
      features: [
        "All Rookie Features",
        "VIP Room Access",
        "Moderate-Risk Signals",
        "Golden Signals",
        "Top Wallet Signals",
        "0% Trade Fee (Beta)",
      ],
      highlight: false
    },
    {
      name: "Pro",
      tokens: "450K $VIZ",
      features: [
        "All Trader Features",
        "Low-Risk Signals",
        "High-Risk Signals",
        "Auto-Trade (Single Strategy)",
        "Terminal Access",
        "Advanced Support",
        "0% Trade Fee (Beta)",
      ],
      highlight: true
    },
    {
      name: "Elite",
      tokens: "750K $VIZ",
      features: [
        "All Pro Features",
        "Auto-Trade (Multiple Strategies)",
        "Top Priority Auto-Trade",
        "Early Alpha Features Access",
        "Terminal Priority Access",
        "Secret Features (Coming Soon)",
        "0% Trade Fee (Beta)",
      ],
      highlight: true
    }
  ];

  const tokenomics = {
    totalSupply: "100 Million $VIZ",
    tax: "3% Buy/Sell Fees",
    liquidityStatus: "Locked",
    ownership: "Retained for Development",
    profitSharing: "90% to Investors (Coming Soon)"
  };

  const featuresList = [
    {
      title: "Real-Time Market Scanner",
      icon: <TrendingUp className="w-6 h-6" />,
      description: "Advanced blockchain scanning for immediate market insights and opportunities"
    },
    {
      title: "AI-Powered Analysis",
      icon: <Bot className="w-6 h-6" />,
      description: "Machine learning algorithms analyze market patterns and predict potential movements"
    },
    {
      title: "Risk Management",
      icon: <Shield className="w-6 h-6" />,
      description: "Smart contract analysis and risk assessment for safer trading"
    },
    {
      title: "Custom Trading Settings",
      icon: <Settings className="w-6 h-6" />,
      description: "Personalize your trading parameters and automate your strategy"
    },
    {
      title: "Community Insights",
      icon: <Users className="w-6 h-6" />,
      description: "Access to exclusive VIP trading community and expert discussions"
    },
    {
      title: "Instant Alerts",
      icon: <Zap className="w-6 h-6" />,
      description: "Real-time notifications for market opportunities and risk alerts"
    }
  ];

  const roadmap = [
    {
      phase: "Phase 1 - Community Foundation",
      status: "completed",
      timestamp: "2022",
      completion: "100%",
      items: [
        { text: "Community Building & Development", status: "completed", date: "Q2 2022" },
        { text: "Initial Technology Stack Development", status: "completed", date: "Q3 2022" },
        { text: "Platform Alpha Launch", status: "completed", date: "Dec 2022" }
      ]
    },
    {
      phase: "Phase 2 - Core Infrastructure",
      status: "completed",
      timestamp: "2023",
      completion: "100%",
      items: [
        { text: "$VIZ Token Launch", status: "completed", date: "Apr 2023" },
        { text: "VIP Trading Room Access", status: "completed", date: "Jun 2023" },
        { text: "Advanced Signal Generation System", status: "completed", date: "Oct 2023" }
      ]
    },
    {
      phase: "Phase 3 - Trading Innovation",
      status: "completed",
      timestamp: "2024 H1",
      completion: "100%",
      items: [
        { text: "Manual Trading Integration", status: "completed", date: "Jan 2024" },
        { text: "AI-Powered Analysis Engine", status: "completed", date: "Mar 2024" },
        { text: "Enhanced Trading Features", status: "completed", date: "Q2 2024" }
      ]
    },
    {
      phase: "Phase 4 - Advanced Trading",
      status: "current",
      timestamp: "2024 H2",
      completion: "75%",
      items: [
        { text: "Automated Trading System", status: "completed", date: "Aug 2024" },
        { text: "Professional Trading Terminal", status: "in_progress", date: "Q4 2024" },
        { text: "Advanced AI Model Optimization", status: "in_progress", date: "Q4 2024" }
      ]
    },
    {
      phase: "Phase 5 - Platform Evolution",
      status: "future",
      timestamp: "2025 H1",
      completion: "0%",
      items: [
        { text: "Simplified Investment Strategies", status: "future", date: "Q1 2025" },
        { text: "Cross-Chain Integration", status: "future", date: "Q1 2025" },
        { text: "Global Marketing Campaign", status: "future", date: "Q2 2025" }
      ]
    }
  ];

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const currentSubtitle = headlines[currentHeadline].subtitle;
    
    const handleNextHeadline = () => {
      setTitleOpacity(0);
      timeoutId = setTimeout(() => {
        setCurrentHeadline((prev) => (prev + 1) % headlines.length);
        setTitleOpacity(1);
        setDisplayText('');
        setIsTyping(true);
      }, 500);
    };

    if (isTyping) {
      if (displayText.length < currentSubtitle.length) {
        timeoutId = setTimeout(() => {
          setDisplayText(currentSubtitle.slice(0, displayText.length + 1));
        }, 50);
      } else {
        timeoutId = setTimeout(() => {
          setIsTyping(false);
        }, 2000);
      }
    } else {
      if (displayText.length > 0) {
        timeoutId = setTimeout(() => {
          setDisplayText(displayText.slice(0, -1));
        }, 30);
      } else {
        timeoutId = setTimeout(handleNextHeadline, 500);
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [displayText, isTyping, currentHeadline, headlines]);

  return (
    <div className="min-h-screen bg-black text-green-500 font-mono">
      <EasterEgg />
      <BlockchainStatus />
      {/* Navigation */}
      <nav className="fixed w-full z-50 border-b border-green-500/20 bg-black/90 backdrop-blur-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <span className="text-xl font-bold">MarketViz</span>
            </div>
            
            {/* Mobile menu button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 rounded-lg border border-green-500/30 hover:bg-green-500/10"
              >
                {isMenuOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
            </div>

            {/* Desktop menu */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="#features" className="hover:text-green-400">Features</a>
              <a href="#membership" className="hover:text-green-400">Membership</a>
              <a href="#tokenomics" className="hover:text-green-400">Tokenomics</a>
              <a href="#roadmap" className="hover:text-green-400">Roadmap</a>
              <a href="https://t.me/marketviz_official" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 className="px-4 py-2 bg-green-500/10 hover:bg-green-500/20 rounded-lg border border-green-500/30">
                Join Community
              </a>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          <div className="px-2 pt-2 pb-3 space-y-1 border-t border-green-500/20 bg-black/90 backdrop-blur-sm">
            <a href="#features" 
               className="block px-3 py-2 rounded-lg hover:bg-green-500/10 hover:text-green-400"
               onClick={() => setIsMenuOpen(false)}>
              Features
            </a>
            <a href="#membership" 
               className="block px-3 py-2 rounded-lg hover:bg-green-500/10 hover:text-green-400"
               onClick={() => setIsMenuOpen(false)}>
              Membership
            </a>
            <a href="#tokenomics" 
               className="block px-3 py-2 rounded-lg hover:bg-green-500/10 hover:text-green-400"
               onClick={() => setIsMenuOpen(false)}>
              Tokenomics
            </a>
            <a href="#roadmap" 
               className="block px-3 py-2 rounded-lg hover:bg-green-500/10 hover:text-green-400"
               onClick={() => setIsMenuOpen(false)}>
              Roadmap
            </a>
            <a href="https://t.me/marketviz_official" 
               target="_blank" 
               rel="noopener noreferrer"
               className="block px-3 py-2 rounded-lg bg-green-500/10 hover:bg-green-500/20 border border-green-500/30"
               onClick={() => setIsMenuOpen(false)}>
              Join Community
            </a>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative min-h-screen flex flex-col pt-16">
        <div className="absolute inset-0 pointer-events-none bg-gradient-to-b from-green-500/5 via-transparent to-green-500/5 animate-pulse opacity-20" />
        
        <div className="flex-1 flex items-center justify-center p-4">
          <div className="text-center space-y-8 max-w-3xl mx-auto">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-6xl font-bold text-green-400 transition-all duration-1000"
                  style={{ opacity: titleOpacity }}>
                {headlines[currentHeadline].title}
              </h1>
              <p className="text-xl md:text-2xl text-green-300/70 min-h-[2.5rem]">
                {displayText}
                <span className="animate-pulse">|</span>
              </p>
            </div>
            
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <a href="https://t.me/marketviztrading_bot" 
                 target="_blank" 
                 rel="noopener noreferrer"
                 className="px-6 py-3 bg-green-500/10 hover:bg-green-500/20 rounded-lg border border-green-500/30 
                            flex items-center justify-center gap-2 group w-full sm:w-auto">
                <Bot className="w-5 h-5 group-hover:animate-pulse" />
                <span>Access Trading Bot</span>
              </a>
              
              {/* Terminal Access Teaser Button */}
              <div className="relative group w-full sm:w-auto">
                {/* Glitch effect overlay */}
                <div className="absolute inset-0 bg-red-500/20 opacity-0 group-hover:opacity-100 
                                transition-opacity duration-200 rounded-lg blur-sm" />
                
                <button 
                  disabled
                  className="w-full px-6 py-3 bg-green-500/5 rounded-lg border border-green-500/30
                             flex items-center justify-center gap-2 relative
                             cursor-not-allowed group-hover:border-red-500/30 transition-colors"
                >
                  <Terminal className="w-5 h-5 group-hover:animate-glitch" />
                  <span className="flex items-center gap-2 flex-wrap justify-center">
                    <span>Terminal Access</span>
                    <span className="text-xs font-mono text-red-400/70 bg-red-500/10 px-2 py-0.5 rounded whitespace-nowrap">
                      COMING Q4
                    </span>
                  </span>
                </button>
                
                {/* Hover tooltip */}
                <div className="absolute -top-12 left-1/2 -translate-x-1/2 w-max
                                opacity-0 group-hover:opacity-100 transition-opacity
                                pointer-events-none">
                  <div className="bg-black/90 text-red-400 text-xs font-mono px-3 py-1.5 rounded border border-red-500/30
                                  flex items-center gap-2 whitespace-nowrap">
                    <AlertTriangle className="w-4 h-4" />
                    <span>Requires Pro/Elite Tier Access</span>
                  </div>
                </div>
              </div>

              <a href="https://app.uniswap.org/#/swap?outputCurrency=0x2C10c0dE3362FF21F8ED6bC7F4AC5e391153fD2c" 
                 target="_blank" 
                 rel="noopener noreferrer"
                 className="px-6 py-3 bg-green-500/10 hover:bg-green-500/20 rounded-lg border border-green-500/30
                            flex items-center justify-center gap-2 group w-full sm:w-auto">
                <ExternalLink className="w-5 h-5 group-hover:rotate-45 transition-transform" />
                <span>Get $VIZ Tokens</span>
              </a>
            </div>
          </div>
        </div>

        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
          <ChevronDown className="w-6 h-6" />
        </div>
      </div>

      {/* Features Section */}
      <section id="features" className="py-20 bg-black/90">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Advanced Trading Features</h2>
          <div className="mb-16">
            <FeatureWidgets />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {featuresList.map((feature) => (
              <div key={feature.title} 
                   className="p-6 bg-green-900/10 rounded-lg border border-green-500/30 
                            hover:bg-green-900/20 transition-colors">
                <div className="flex items-center gap-3 mb-4">
                  {feature.icon}
                  <h3 className="text-xl font-bold">{feature.title}</h3>
                </div>
                <p className="text-green-300/70">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Membership Tiers */}
      <section id="membership" className="py-20 bg-black/90 relative overflow-hidden">
        {/* Animated grid background */}
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#0f172a_1px,transparent_1px),linear-gradient(to_bottom,#0f172a_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_50%,#000_70%,transparent_100%)]" />
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <h2 className="text-4xl font-bold text-center mb-16">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-green-600">
              Access Tiers
            </span>
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {membershipTiers.map((tier) => (
              <div key={tier.name} 
                   className={`relative group transform hover:scale-105 transition-all duration-300
                     ${tier.highlight ? 'hover:z-10' : ''}`}>
                  
                {/* Glowing effect for highlighted tiers */}
                {tier.highlight && (
                  <div className="absolute inset-0 bg-green-500/5 blur-xl" />
                )}

                <div className="relative p-6 bg-black/60 backdrop-blur-sm rounded-lg border border-green-500/30 h-full">
                  {/* Decorative corners */}
                  <div className="absolute top-0 left-0 w-4 h-4 border-t border-l border-green-500/50" />
                  <div className="absolute top-0 right-0 w-4 h-4 border-t border-r border-green-500/50" />
                  <div className="absolute bottom-0 left-0 w-4 h-4 border-b border-l border-green-500/50" />
                  <div className="absolute bottom-0 right-0 w-4 h-4 border-b border-r border-green-500/50" />

                  {/* Tier name with matrix effect */}
                  <div className="text-xl font-bold mb-2 flex items-center gap-2">
                    <span className="text-green-400">[</span>
                    {tier.name}
                    <span className="text-green-400">]</span>
                  </div>

                  {/* Token amount */}
                  <div className="text-2xl font-bold text-green-400 mb-6 font-mono">
                    {tier.tokens}
                  </div>

                  {/* Features list */}
                  <ul className="space-y-3">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex items-center gap-2 group/item">
                        <span className="text-green-400 opacity-50 group-hover/item:opacity-100 transition-opacity">⟩</span>
                        <span className={`text-green-300/70 group-hover/item:text-green-300 transition-colors
                          ${feature.includes('Beta') ? 'text-yellow-400/70' : ''}
                          ${feature.includes('???') ? 'text-purple-400/70' : ''}`}>
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>

                  {/* Special badge for highlighted tiers */}
                  {tier.highlight && (
                    <div className="absolute -top-2 -right-2 bg-green-500/20 px-3 py-1 rounded-full text-xs font-mono border border-green-500/30">
                      PREMIUM
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Add this after the membership tiers grid and before the beta notice */}
          <div className="mt-16 flex flex-col items-center space-y-6">
            {/* Primary CTA */}
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <a href="https://t.me/marketviztrading_bot" 
                 target="_blank" 
                 rel="noopener noreferrer"
                 className="px-6 py-3 bg-green-500/10 hover:bg-green-500/20 rounded-lg border border-green-500/30 
                            flex items-center justify-center gap-2 group">
                <Bot className="w-5 h-5 group-hover:animate-pulse" />
                <span>Access Trading Bot</span>
              </a>

              <a href="https://app.uniswap.org/#/swap?outputCurrency=0x2C10c0dE3362FF21F8ED6bC7F4AC5e391153fD2c" 
                 target="_blank" 
                 rel="noopener noreferrer"
                 className="px-6 py-3 bg-green-500/10 hover:bg-green-500/20 rounded-lg border border-green-500/30
                            flex items-center justify-center gap-2 group">
                <ExternalLink className="w-5 h-5 group-hover:rotate-45 transition-transform" />
                <span>Get $VIZ Tokens</span>
              </a>
            </div>

            {/* Terminal teaser for Pro/Elite */}
            <div className="relative group">
              <div className="absolute inset-0 bg-red-500/20 opacity-0 group-hover:opacity-100 
                              transition-opacity duration-200 rounded-lg blur-sm" />
              
              <button 
                disabled
                className="px-6 py-3 bg-green-500/5 rounded-lg border border-green-500/30
                           flex items-center justify-center gap-2 relative
                           cursor-not-allowed group-hover:border-red-500/30 transition-colors"
              >
                <Terminal className="w-5 h-5 group-hover:animate-glitch" />
                <span className="flex items-center gap-2">
                  Terminal Access
                  <span className="text-xs font-mono text-red-400/70 bg-red-500/10 px-2 py-0.5 rounded">
                    COMING Q4
                  </span>
                </span>
              </button>
              
              <div className="absolute -top-12 left-1/2 -translate-x-1/2 w-max
                              opacity-0 group-hover:opacity-100 transition-opacity
                              pointer-events-none">
                <div className="bg-black/90 text-red-400 text-xs font-mono px-3 py-1.5 rounded border border-red-500/30
                                flex items-center gap-2">
                  <AlertTriangle className="w-4 h-4" />
                  <span>Requires Pro/Elite Tier Access</span>
                </div>
              </div>
            </div>
          </div>

          {/* Beta notice */}
          <div className="mt-12 text-center text-sm text-green-500/70">
            <span className="font-mono">[BETA]</span> Trading fees are currently waived for all tiers during beta phase
          </div>
        </div>
      </section>

      {/* Tokenomics */}
      <section id="tokenomics" className="py-20 bg-black/90 relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-green-500/10 via-transparent to-transparent" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Tokenomics</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {Object.entries(tokenomics).map(([key, value], index) => (
              <div
                key={key}
                className="relative group hover:z-10 transform hover:scale-105 transition-all duration-300"
              >
                <div className="absolute inset-0 bg-green-500/5 blur-xl" />
                <div className="relative p-6 bg-black/80 backdrop-blur-sm rounded-lg border border-green-500/30">
                  <div className="absolute top-0 right-0 p-2 text-xs text-green-500/50 font-mono">
                    [{index.toString().padStart(2, '0')}]
                  </div>
                  <h3 className="text-lg font-bold mb-4 text-green-400">
                    {key.replace(/([A-Z])/g, ' $1').trim()}
                  </h3>
                  <div className="flex items-center gap-2">
                    <span className="text-green-500/50">⟩</span>
                    <p className="text-green-300 font-mono">{value}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Roadmap */}
      <section id="roadmap" className="py-20 bg-black relative overflow-hidden">
        {/* Animated background grid */}
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#0f172a_1px,transparent_1px),linear-gradient(to_bottom,#0f172a_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_50%,#000_70%,transparent_100%)]" />
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <h2 className="text-4xl font-bold text-center mb-16">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-green-600">
              Development Roadmap
            </span>
          </h2>

          <div className="relative">
            {/* Central connecting line */}
            <div className="absolute left-4 md:left-1/2 top-0 bottom-0 w-px bg-gradient-to-b from-green-500/50 via-green-500/20 to-transparent" />

            <div className="space-y-24">
              {roadmap.map((phase, index) => (
                <div key={phase.phase} className="relative group">
                  {/* Phase indicator */}
                  <div className={`absolute left-4 md:left-1/2 top-0 -translate-x-1/2 flex flex-col items-center gap-2
                    ${phase.status === 'completed' ? 'text-green-400' : 
                      phase.status === 'current' ? 'text-green-500' : 'text-green-800'}`}>
                    
                    {/* Hexagonal node */}
                    <div className={`w-8 h-8 relative ${phase.status === 'current' ? 'animate-pulse' : ''}`}>
                      <div className="absolute inset-0 bg-black border-2 rotate-45 transform origin-center
                        ${phase.status === 'completed' ? 'border-green-400 bg-green-400/20' :
                          phase.status === 'current' ? 'border-green-500 bg-green-500/20' :
                          'border-green-800 bg-green-900/10'}" />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <span className="text-xs font-mono">{index + 1}</span>
                      </div>
                    </div>

                    {/* Timestamp */}
                    <div className="text-xs font-mono opacity-75">{phase.timestamp}</div>
                  </div>

                  {/* Content card */}
                  <div className={`ml-16 md:ml-[calc(50%+2rem)] p-6 relative
                    ${phase.status === 'completed' ? 'opacity-60' :
                      phase.status === 'current' ? 'opacity-100' : 'opacity-40'}`}>
                    
                    {/* Glowing background for current phase */}
                    {phase.status === 'current' && (
                      <div className="absolute inset-0 bg-green-500/5 blur-xl" />
                    )}

                    {/* Main content container */}
                    <div className="relative bg-black/60 backdrop-blur-sm rounded-lg border 
                      ${phase.status === 'completed' ? 'border-green-500/30' :
                        phase.status === 'current' ? 'border-green-500 shadow-[0_0_15px_rgba(34,197,94,0.2)]' :
                        'border-green-800/30'}">
                      
                      {/* Header */}
                      <div className="p-4 border-b border-green-500/20">
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center gap-3">
                            <span className="text-xs font-mono text-green-500/50">
                              [{index + 1}.0]
                            </span>
                            <h3 className="text-xl font-bold">{phase.phase}</h3>
                          </div>
                          <div className="text-sm font-mono">
                            <span className={`
                              ${phase.status === 'completed' ? 'text-green-400' :
                                phase.status === 'current' ? 'text-green-500' : 'text-green-800'}
                            `}>
                              {phase.completion}
                            </span>
                          </div>
                        </div>

                        {/* Progress bar */}
                        <div className="h-1 bg-green-900/30 rounded-full overflow-hidden">
                          <div className={`h-full transition-all duration-1000
                            ${phase.status === 'completed' ? 'bg-green-400' :
                              phase.status === 'current' ? 'bg-green-500' : 'bg-green-800'}
                          `}
                          style={{ width: phase.completion }} />
                        </div>
                      </div>

                      {/* Items list */}
                      <ul className="p-4 space-y-3">
                        {phase.items.map((item) => (
                          <li key={item.text} className="flex items-center gap-3 group/item">
                            {/* Status indicator */}
                            <span className={`w-2 h-2 rounded-full 
                              ${item.status === 'completed' ? 'bg-green-400' :
                                item.status === 'in_progress' ? 'bg-green-500 animate-pulse' :
                                'bg-green-800'}`} />
                            
                            {/* Item content */}
                            <div className="flex-1 flex items-center justify-between">
                              <span className={`
                                ${item.status === 'completed' ? 'text-green-300/70' :
                                  item.status === 'in_progress' ? 'text-green-300' : 'text-green-300/50'}
                              `}>
                                {item.text}
                              </span>
                              <span className="text-xs font-mono text-green-500/50">{item.date}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Risk Warning */}
      <section className="py-8 bg-black/80">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center gap-2 text-yellow-500/80">
            <AlertTriangle className="w-5 h-5" />
            <p className="text-sm">Trading cryptocurrencies involves significant risk. Only trade with funds you can afford to lose.</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="border-t border-green-500/20 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <span className="text-sm text-green-300/70">© 2024 MarketViz. All rights reserved.</span>
            <div className="flex items-center gap-4">
              <a href="https://twitter.com/marketvizapp" target="_blank" rel="noopener noreferrer"
                 className="hover:text-green-400">
                <Twitter className="w-5 h-5" />
              </a>
              <a href="https://t.me/marketviz_official" target="_blank" rel="noopener noreferrer"
                 className="hover:text-green-400">
                <Send className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;